@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.file-input{
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: red;
  cursor: pointer;
  top: 0;
  left: 0;
}
.w-full{
  width: 100%;
}

.card-file{
  border:3px dotted #ddd;
  padding: 20px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #9b9797;
  transition: all .2s;
  position: relative;
}

.card-file:hover{
  color: #282626;
}

.hover-link:hover{
  color:blue;
  border-bottom: 1px solid #ddd;
}

.textarea-transaction{
  height: 34px;
  padding: 10px 5px;
  min-height: 30px;
  border: 1px solid #ddd;
  padding: 3px 5px;
  border-radius: 5px;
  width: 100%;
  font-size: 12px;
}

.table-dinamic {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table-dinamic  td, .table-dinamic th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px 5px;
}

.range-picker{
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  appearance: none;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 5px 10px;
  border-radius: 7px;
  border: 1px solid #ddd;
}

.swall-confirm-button{
  appearance: button;
  background-color: rgb(13, 110, 253);
  border-bottom-left-radius: 3.5px;
  border-bottom-right-radius: 3.5px;
  border-top-left-radius: 3.5px;
  border-top-right-radius: 3.5px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 12.25px;
  font-weight: 400;
  line-height: 18.3833px;
  margin-bottom: 3.5px;
  margin-left: 1.75px;
  margin-right: 1.75px;
  margin-top: 3.5px;
  scroll-margin-bottom: 100px;
  scroll-margin-top: 80px;
  text-align: center;
  text-decoration-color: rgb(255, 255, 255);
  text-decoration-line: none;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  text-transform: none;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-property: color, background-color, border-color, box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  user-select: none;
  vertical-align: middle;
  padding: 7px;
  margin-right: 1rem;
}

.text-danger{
  color:red;
}

.uppercase{
  text-transform: uppercase;
}

.list-none{
  list-style: none;
}

.p-0{
  padding:0;
}

.m-0{
  margin: 0;
}

.flex{
  display: flex;
}

.font-11{
  font-size: 11px;
}

.font-12{
  font-size: 12px;
}

.font-bold{
  font-weight: bold;
}

.cashflow{
  display: flex;
  overflow-x: auto;
  padding: 7px;
  flex-wrap: wrap;
}

.cashflow-list{
  width: 50%;
}

.cashflow-balance{
  width:50%;
  display: flex;
  overflow-x: auto;
}

.cashflow-balance .float-left{
  flex-shrink: 0;
}

.cashflow th{
  text-align: left;
}
.cashflow td{
  text-align: left;
  font-size: 12px;
}

.cashflow table{
  border-collapse: collapse;
}

.cashflow table tr{
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.cashflow table td, .cashflow table th{
  padding: 7px;
}

.text-right{
  text-align: right!important;
}

.float-left{
  float: left;
}

.border-r{
  border-right: 1px solid #eee;
}

.border-0{
  border: 0px !important;
}

.cashflow table .tr-report{
  background-color:#aaf2f2; 
  border-bottom:1px solid #52eeee;
  border-right:1px solid #52eeee;
}

.color-gray-400{
  color: rgb(110, 107, 123);
}

.color-gray-500{
  color: rgb(94, 88, 115);
}
